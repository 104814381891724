import {Warranty} from "../helpers/warranty/Warranty";
import {StepSlider} from "./stepslider/StepSlider";

export const Concrete = () => {
    return (
        <main className="concrete__container">
            <Warranty duration={10} />
            <section>
                <h2>Proceso de construcción</h2>
                <p>Dar lo mejor es darte una piscina que tenga durabilidad y calidad.</p>
            </section>
            <section>
                <StepSlider />
            </section>
        </main>
    )
}
