export const Branding = () => {
    return (
        <div className="brands__container">
            <img src="/assets/vulcano.png" alt="Vulcano"/>
            <img src="/assets/plufilt.png" alt="Plufilt" />
            <img src="/assets/cepex.png" alt="Cepex" />
            <img src="/assets/tigre.png" alt="Tigre" />
            <img src="/assets/pooled.png" alt="Pooled" />
            <img src="/assets/aguas.png" alt="Aguas" />
        </div>
    )
}
