import logo_facebook from "./facebook.svg"
import logo_instagram from "./instagram.svg"
import logo_tiktok from "./tiktok.svg"
import logo_youtube from "./youtube.svg"

export const Social = ({facebook, instagram, youtube, tiktok}) => {
    return (
        <div className="section__social">
            <h4>Redes Sociales</h4>
            <a href={facebook} target="_blank" rel="noreferrer">
                <img src={logo_facebook} alt="Facebook"/>
            </a>
            <a href={instagram} target="_blank" rel="noreferrer">
                <img src={logo_instagram} alt="Instagram"/>
            </a>
            <a href={youtube} target="_blank" rel="noreferrer">
                <img src={logo_youtube} alt="YouTube"/>
            </a>
            <a href={tiktok} target="_blank" rel="noreferrer">
                <img src={logo_tiktok} alt="Tik-Tok"/>
            </a>
        </div>
    )
}