import {StepControl} from "./control/StepControl";
import {StepDescriptionContainer} from "./description/StepDescriptionContainer";
import {StepImgContainer} from "./imagecontainer/StepImgContainer";
import {useState} from "react";

export const StepSlider = () => {
    const [step, setStep] = useState(0)
    const ctrlClbk = (stp) => {
        setStep(stp/20)
    }

    return (
        <div className="step__slider">
            <StepControl ctrlClbk={ctrlClbk} />
            <div className='step__content'>
                <StepImgContainer step={step} />
                <StepDescriptionContainer step={step} />
            </div>
        </div>
    )
}