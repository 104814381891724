import {useState, useEffect} from "react";

export const StepControl = ({ctrlClbk}) => {
    const [step, setStep] = useState(0)
    const ctrlHndlr = (e) => {
        e.preventDefault()
        switch (e.currentTarget.id) {
            case 'ctrl_btn_01':
                setStep(0)
                break
            case 'ctrl_btn_02':
                setStep(20)
                break
            case 'ctrl_btn_03':
                setStep(40)
                break
            case 'ctrl_btn_04':
                setStep(60)
                break
            case 'ctrl_btn_05':
                setStep(80)
                break
            case 'ctrl_btn_06':
                setStep(100)
                break
            default:
                setStep(0)
                break
        }
    }
    useEffect(()=>{

    },[step])
    useEffect(()=>{
        document.getElementById("ctrl_bar").style.width = `calc(${step}% - (${step} * 0.0125rem))`
        ctrlClbk(step)
        const interval = setInterval(() => {
            if (step < 100) {
                setStep(step + 20)
            } else {
                setStep(0)
            }
        }, 4000)
        return () => clearInterval(interval)
    },[step, ctrlClbk])
    return (
        <div className="step__control">
            <div className="control__bar_disabled"></div>
            <div className="control__bar" id="ctrl_bar"></div>
            <div className="control__button" id="ctrl_btn_01" onClick={ctrlHndlr}><div><div></div></div></div>
            <div className="control__button" id="ctrl_btn_02" onClick={ctrlHndlr}><div><div></div></div></div>
            <div className="control__button" id="ctrl_btn_03" onClick={ctrlHndlr}><div><div></div></div></div>
            <div className="control__button" id="ctrl_btn_04" onClick={ctrlHndlr}><div><div></div></div></div>
            <div className="control__button" id="ctrl_btn_05" onClick={ctrlHndlr}><div><div></div></div></div>
            <div className="control__button" id="ctrl_btn_06" onClick={ctrlHndlr}><div><div></div></div></div>
        </div>
    )
}