import {StepImg} from "./StepImg";
import {useEffect} from "react";

export const StepImgContainer = ({step}) => {
    useEffect(()=>{
        const images = document.getElementsByClassName('step__image')
        for (var steps = 0; steps < images.length; steps++) {
            if (steps > step) {
                images[steps].style.display = 'none'
            } else {
                images[steps].style.display = 'block'
            }
        }
    },[step])
    return (
        <div className="step__images">
            <StepImg imgUrl={'./assets/steps/step01.webp'} text={'Paso 1'} offset={'0'} opacity={1}/>
            <StepImg imgUrl={'./assets/steps/step02.webp'} text={'Paso 2'} offset={'10'} opacity={1}/>
            <StepImg imgUrl={'./assets/steps/step03.webp'} text={'Paso 3'} offset={'20'} opacity={1}/>
            <StepImg imgUrl={'./assets/steps/step04.webp'} text={'Paso 4'} offset={'30'} opacity={1}/>
            <StepImg imgUrl={'./assets/steps/step05.webp'} text={'Paso 5'} offset={'40'} opacity={1}/>
            <StepImg imgUrl={'./assets/steps/step06.webp'} text={'Paso 6'} offset={'50'} opacity={1}/>
            <img src="./assets/steps/placeholder.webp" alt="placeholder" id='step__placeholder' />
        </div>
    )
}