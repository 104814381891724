import { Icon } from "@iconify/react"

export const StepDescription = ({title,description,cta_text,cta_action}) => {
    return (
        <div className={'step__description'}>
            <h3>{title}</h3>
            <p>{description}</p>
            {/*<a href={cta_action} className={'description__button'}>{cta_text}</a>*/}
            <a href={"https://wa.me/5491160140192?text=Hola%20quiero%20presupuesto%20para%20piscina%20de%20concreto"} target={"_blank"} rel={'noreferrer'} className="btn btn-budget"><Icon icon={'bi:whatsapp'} />Solicitar presupuesto</a>
        </div>
    )
}